const TEXT = {
  "Email ID": "Email ID",
  "Transaction ID": "Transaction ID",
  "Free DNS": "Free DNS",
  "Activation Logs": "Activation Logs",
  "Restore Logs": "Restore Logs",
  "Pixi enabled": "Pixi enabled",
  "Pixi secret key": "Pixi secret key",
  "Pixi x login key": "Pixi x login key",
  "Pixi x transaction key": "Pixi x transaction key",
  "Coinbase api key": "Coinbase api key",
  "Coinbase enabled": "Coinbase enabled",
  DNS: "DNS",
  Otp: "Otp",
  "Device key": "Device key",
  "Authorization type": "Authorization type",
  "Sidebar logo size": "Sidebar logo size",
  "Total links": "Total links",
  "Total earnings": "Total earnings",
  "Earnings Wallet": "Earnings Wallet",
  "Activated devices": "Activated devices",
  "Subresellers count": "Subresellers count",
  "Activation given": "Activation given",
  "Activation taken": "Activation taken",
  "Balance on hold": "Balance on hold",
  "Take activations": "Take activations",
  "Google reCAPTCHA": "Google reCAPTCHA",
  "Repeat notification": "Repeat notification",
  "SITE KEY": "SITE KEY",
  "Add notification": "Add notification",
  "Edit notification": "Edit notification",
  "Created date": "Created date",
  "Are you sure you want to freeze?": "Are you sure you want to freeze?",
  "Are you sure you want to unfreeze?": "Are you sure you want to unfreeze?",
  Unfreeze: "Unfreeze",
  "Mac address": "Mac address",
  Repeat: "Repeat",
  Status: "Status",
  Received: "Received",
  "End date": "End date",
  "Start date": "Start date",
  "Total send": "Total send",
  "Send to": "Send to",
  Freeze: "Freeze",
  Notification: "Notification",
  "SECRET KEY": "SECRET KEY",
  Balance: "Balance",
  Disable: "Disable",
  View: "View",
  Delete: "Delete",
  "Location url": "Location url",
  "Location name": "Location name",
  Count: "Count",
  "Reseller id": "Reseller id",
  "Add activations": "Add activations",
  "Add News": "Add News",
  Name: "Name",
  Surname: "Surname",
  Username: "Username",
  Login: "Login",
  Cancel: "Cancel",
  Save: "Save",
  input_yourUser_name: "Please input your Username!",
  "Please input your Password": "Please input your Password",
  useremail: "Useremail",
  Dashboard: "Dashboard",
  Password: "Password",
  "Device manager": "Device manager",
  "Payment History": "Payment History",
  Languages: "Languages",
  Application: "Application",
  Blacklist: "Blacklist",
  Packages: "Packages",
  Withdraw: "Withdraw",
  Activation: "Activation",
  "Referral Earnings": "Referral Earnings",
  "Referral links": "Referral links",
  "Referral/Reseller": "Referral/Reseller",
  Referral: "Referral",
  Server: "Server",
  Select: "Select",
  Logout: "Logout",
  Profile: "Profile",
  Today: "Today",
  Users: "Users",
  Playlist: "Playlist",
  Paymnets: "Paymnets",
  Online: "Online",
  Servers: "Servers",
  Ram: "Ram",
  Cpu: "Cpu",
  Uptime: "Uptime",
  Total: "Total",
  "Total Used": "Total Used",
  Devices: "Devices",
  Payment: "Payment",
  Device: "Device",
  Phone: "Phone",
  Company: "Company",
  Address: "Address",
  country: "Country",
  "Is Referral": "is Referral",
  "Is Reseller": "is Reseller",
  "Yearly activation": "Yearly activation",
  Host: "Host",
  Default: "Default",
  "Referral earnings total": "Referral earnings total",
  count: "Count",
  price: "Price",
  DevicesPackages: "Devices Packages",
  "Switch amount": "Switch amount",
  Earning: "Earning",
  "Switch amount": "Switch amount",
  id: "id",
  Ip: "Ip",
  Mac: "Mac",
  "Device id": "Device id",
  Value: "Value",
  Key: "Key",
  Code: "Code",
  Date: "Date",
  "Last Online": "Last Online",
  Model: "Model",
  "Activation type": "Activation type",
  User: "User",
  "Free trial": "Free trial",
  "Transaction id": "Transaction id",
  Amount: "Amount",
  Currency: "Currency",
  "Payment method": "Payment method",
  Active: "Active",
  Payed: "Payed",
  Count: "Count",
  Price: "Price",
  Reseller: "Reseller",
  Method: "Method",
  Country: "Country",
  "BIC/SWIFT code": "BIC/SWIFT code",
  Accepted: "Accepted",
  Accept: "Accept",
  "Transaction id": "Transaction id",
  "M3U8 URL": "M3U8 URL",
  "Expired Date": "Expired Date",
  Reject: "Reject",
  Address: "Address",
  Description: "Description",
  "Header text": "Header text",
  Clicks: "Clicks",
  "Total activations": "Total activations",
  "Activation count": "Activation count",
  Balance: "Balance",
  "IP address": "IP address",
  Referral: "Referral",
  Edit: "Edit",
  Enable: "Enable",
  Desible: "Desible",
  "Support phone number": "Support phone number",
  "Support email address": "Support email address",
  Currencies: "Currencies",
  "Stripe enabled": "Stripe enabled",
  "Stripe public key": "Stripe public key",
  "Stripe webhook secret": "Stripe webhook secret",
  "Paypal enabled": "Paypal enabled",
  "Paypal client id": "Paypal client id",
  "Paypal client secret": "Paypal client secret",

  "Stripe secret key": "Stripe secret key",
  "Enter activation text": "Enter activation text",
  name_surname: "Name & Surname",
  "Admin Config": "Admin Config",
  "Enter accept text": "Enter accept text",
  "Application admin page logo": "Application admin page logo",
  Backdrop: "Backdrop",
  "Background image": "Background image",
  "Reseller Config": "Reseller Config",
  "User Packages": "User Packages",
  Schedule: "Schedule",
  "Schedule time": "Schedule time",
  "Application backdrop": "Application backdrop",
  "Application background image": "Application background image",
  Settings: "Settings",
  Statuses: "Statuses",
  "Referrals withdraw": "Referrals withdraw",
  "Referrals activation": "Referrals activation",
  "Add Blacklist": "Add Blacklist",
  Package: "Package",
  Key: "Key",
  Branding: "Branding",
  "Last online": "Last online",
  "Device Created date": "Device Created date",
  Url: "Url",
  "Add Playlist": "Add Playlist",
  Edit: "Edit",
  Add: "Add",
  "Are you sure you want to delete?": "Are you sure you want to delete?",
  CANCEL: "CANCEL",
  ACCEPT: "ACCEPT",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  "Do you want to logout ?": "Do you want to logout ?",
  DELETE: "DELETE",
  LOGOUT: "LOGOUT",
  Port: "Port",
  SMPT: "SMPT",
  Devices: "Devices",
  Delete: "Delete",
  Payment: "Payment",
  "Withdraw requests": "Withdraw requests",
  Earnings: "Earnings",
  Links: "Links",
  "Reseller activation": "Reseller activation",
  Pricing: "Pricing",
  "Activation needed": "Activation needed",
  Activate: "Activate",
  "Device model": "Device model",
  "Package name": "Package name",
  REJECT: "REJECT",
  "Device os": "Device os",
  "Expired date": "Expired date",
  "Free trial expired": "Free trial expired",
  Recommended: "Recommended",
  Payments: "Payments",
  "Application Favicon": "Application Favicon",
  "Please input your username!": "Please input your username!",
  "Forgot password": "Forgot password",
  "Back to login": "Back to login",
  "Send email": "Send email",
  "New password": "New password",
  "Please confirm your password!": "Please confirm your password!",
  "Reset password": "Reset password",
  "Confirm password": "Confirm password",
  "Menu logo": "Menu logo",
  "Application name": "Application name",
  newsPage: "NewsPage",
  News: "News",
  Title: "Title",
  "Save Uploads": "Save Uploads",
  Images: "Images",
  Link: "Link",
  Logo: "Logo",
  Support: "Support",
  Message: "Message",
  Send: "Send",
  "Payment settings": "Payment settings",
  Chat: "Chat",
  New: "New",
  Unanswered: "Unanswered",
  All: "All",
  "Please input your username": "Please input your username",
  "Please input name": "Please input name",
  "Please input  surname": "Please input  surname",
  "Please input email": "Please input email",
  "Subscription id": "Subscription id",
  "Are you sure you want to disable?": "Are you sure you want to disable",
  "Are you sure you want to enable?": "Are you sure you want to enable?",
  "Advanced web settings": "Advanced web settings",
  OK: "OK",

  "Created date": "Created date",
  "Gift Codes": "Gift Codes",
  "GIFT Code": "GIFT Code",
  Type: "Type",
  Comment: "Comment",

  "Reseller login background": "Reseller login background",
  "Admin login background": "Admin login background",
  "Stripe secret key": "Stripe secret key",
  "Enter activation text": "Enter activation text",
  name_surname: "Name & Surname",
  "Admin Config": "Admin Config",
  "Enter accept text": "Enter accept text",
  "Application admin page logo": "Application admin page logo",
  Backdrop: "Backdrop",
  "Background image": "Background image",
  "Reseller Config": "Reseller Config",
  "User Packages": "User Packages",
  Schedule: "Schedule",
  "Schedule time": "Schedule time",
  "Application backdrop": "Application backdrop",
  "Application background image": "Application background image",
  Settings: "Settings",
  Statuses: "Statuses",
  "Referrals withdraw": "Referrals withdraw",
  "Referrals activation": "Referrals activation",
  "Add Blacklist": "Add Blacklist",
  Package: "Package",
  Key: "Key",
  Branding: "Branding",
  "Last online": "Last online",
  "Device Created date": "Device Created date",
  Url: "Url",
  "Add Playlist": "Add Playlist",
  Edit: "Edit",
  Add: "Add",
  "Are you sure you want to delete?": "Are you sure you want to delete?",
  CANCEL: "CANCEL",
  ACCEPT: "ACCEPT",
  ENABLE: "ENABLE",
  DISABLE: "DISABLE",
  "Do you want to logout ?": "Do you want to logout ?",
  DELETE: "DELETE",
  LOGOUT: "LOGOUT",
  Port: "Port",
  SMPT: "SMPT",
  Devices: "Devices",
  Delete: "Delete",
  Payment: "Payment",
  "Withdraw requests": "Withdraw requests",
  Earnings: "Earnings",
  Links: "Links",
  "Reseller activation": "Reseller activation",
  Pricing: "Pricing",
  "Activation needed": "Activation needed",
  Activate: "Activate",
  "Device model": "Device model",
  "Package name": "Package name",
  REJECT: "REJECT",
  "Device os": "Device os",
  "Expired date": "Expired date",
  "Free trial expired": "Free trial expired",
  Recommended: "Recommended",
  Payments: "Payments",
  "Application Favicon": "Application Favicon",
  "Please input your username!": "Please input your username!",
  "Forgot password": "Forgot password",
  "Back to login": "Back to login",
  "Send email": "Send email",
  "New password": "New password",
  "Please confirm your password!": "Please confirm your password!",
  "Reset password": "Reset password",
  "Confirm password": "Confirm password",
  "Menu logo": "Menu logo",
  "Application name": "Application name",
  newsPage: "NewsPage",
  News: "News",
  Title: "Title",
  "Save Uploads": "Save Uploads",
  Images: "Images",
  Link: "Link",
  Logo: "Logo",
  Support: "Support",
  Message: "Message",
  Send: "Send",
  "Payment settings": "Payment settings",
  Chat: "Chat",
  New: "New",
  Unanswered: "Unanswered",
  All: "All",
  "Please input your username": "Please input your username",
  "Please input name": "Please input name",
  "Please input  surname": "Please input  surname",
  "Please input email": "Please input email",
  "Subscription id": "Subscription id",
  "Are you sure you want to disable?": "Are you sure you want to disable?",
  "Are you sure you want to enable?": "Are you sure you want to enable?",
  "Advanced web settings": "Advanced web settings",
  OK: "OK",
  "Created date": "Created date",
  "Are you sure you want to free trial?":
    "Are you sure you want to free trial?",
  SMTP: "SMTP",
  "Please input your username": "Please input your username",
  "Lahza enabled": "Lahza enabled",
  "Lahza Secret Key": "Lahza Secret Key",
  "Lahza Public Key": "Lahza Public Key",
  "Please enter your Lahza client id": "Please enter your Lahza client id",
  "Please enter your Lahza store key": "Please enter your Lahza store key",
  "Please enter Lahza API URL": "Please enter Lahza API URL",
  "Lahza API URL": "Lahza API URL",
  "Free trial duration": "Free trial duration",
  "View more": "View more",
  Viewed: "Viewed",
  Banner: "Banner",
  "Release date": "Release date",
  Genre: "Genre",
  "MOST VIEWED CHANNELS": "MOST VIEWED CHANNELS",
  "MOST VIEWED MOVIES": "MOST VIEWED MOVIES",
  "MOST VIEWED SERIES": "MOST VIEWED SERIES",
  "Ref/Res": "Ref/Res",
  "Package for": "Package for",
  "Ads Enabled": "Ads Enabled",
  "Access code": "Access code",
  "API Key": "API Key",
  HOST: "HOST",
  "Reseller name": "Reseller name",
  "Reseller email": "Reseller email",
  "Total Activations": "Total Activations",
  "Activated devices": "Activated devices",
  "IP address": "IP address",
  "Subresellers count": "Subresellers count",
  "Reseller info": "Reseller info",
  Method: "Method",
  "Transaction id": "Transaction id",
  Count: "Count",
  "Total activations": "Total activations",
  "Free Trial Packages": "Free Trial Packages",
  "Subscription packages": "Subscription packages",
  "Put IDs with commas": "Put IDs with commas",
  "Bouquet IDs": "Bouquet IDs",
  "Edit Playlist": "Edit Playlist",
  "Protect Playlist": "Protect Playlist",
  "Confirm PIN": "Confirm PIN",
  "Set PIN": "Set PIN",
  Credit: "Credit",
  "Add Credits": "Add Credits",
  "Take Credits": "Take Credits",
  "Credit needed": "Credit needed",
  "Reseller Credit": "Reseller Credit",
  Credits: "Credits",
  "Credit name": "Credit name",
  "Update playlist": "Update playlist",
  "Manage categories": "Manage categories",
  Categories: "Categories",
  Email: "Email",
  Time: "Time",
  Deactivate: "Deactivate",
  "Are you sure you want to deactivate device?":
    "Are you sure you want to deactivate device?",
  DEACTIVATE: "DEACTIVATE",
  "Package Name": "Package Name",
  "External API link": "External API link",
  "Restore Key": "Restore Key",
  "Parent Reseller": "Parent Reseller",
  "Show paypal card": "Show paypal card",
  "App version": "App version",
  Selected: "Selected",
  Logs: "Logs",
  Protected: "Protected",
  Applications: "Applications",
  Location: "Location",
};

export default TEXT;
